// eslint-disable-next-line
import renderComponents from './components.jsx';

const NAME = 'sw-railway';

class RailwayModule {
  constructor(name, context, opts) {
    this.box = opts.box;
    this.name = name;
    this.context = context;

    const trainCancelService = this.context.getService('trainCancel');
    const featureFlagsService = this.context.getService('FeatureFlags');
    const railwayWarningService = this.context.getService('railwayWarning');

    this.page = renderComponents(this.box, { trainCancelService, featureFlagsService, railwayWarningService });
  }

  destroy() {
    this.page.unmount();
  }
}

export default function (core) {
  core.addModule(NAME, RailwayModule);
}
