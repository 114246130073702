import renderComponents from './component';

const NAME = 'sw-regions-page';
const DEFAULT_LABEL_SEARCHINPUT = 'Поиск регионов';

class RegionsModule {
  constructor(name, context, opts) {
    this.name = name;
    this.box = opts.box;
    this.context = context;

    const regionsService = context.getService('Regions');
    const appService = context.getService('App');
    const workplaceService = this.context.getService('Workplace');

    const { params: { regionsPageId, regionId } } = appService.get();

    if (regionsPageId === 'searchRegions') {
      regionsService.setSearchFilter(DEFAULT_LABEL_SEARCHINPUT, '');
    }

    this.page = renderComponents(this.box, {
      regionsService,
      workplaceService,
      regionsPageId,
      regionId,
      toRegion: this.toRegion,
    });
  }

  toRegion = (id) => {
    const path = `/regions/region/${id}`;

    this.context.navigate(path);
  };

  destroy() {
    this.page.unmount();
    const regionsService = this.context.getService('Regions');
    regionsService.unSetSearchFilter();
  }
}

export default function (core) {
  core.addModule(NAME, RegionsModule);
}
