import React from 'react';
import PropTypes from 'prop-types';

import { Dialog, Button, Input, Select } from 'sw-ui';

import COMPONENTS from '../../../../bi/constants/components';
import { TRAIN_ITEMS } from '../../../../bi/constants/trainDescription';
import { NONE } from '../../../../bi/constants/statistic';

import { FlatButton } from '../../../../components/button';

import styles from '../../styles/railway.module.css';

const {
  SELECT: {
    THEME: { BORDER },
  },
  BUTTON: {
    THEME: { RED },
  },
  DIALOG: {
    WIDTH: { MIDDLE },
  },
} = COMPONENTS;

const LABELS = {
  TITLE: 'Отмена ж/д билета',
  PROVIDER: 'Провайдер',
  ORDER_ID: 'Номер заказа',
  NUMBER_ID: 'Номер билета',
  CONFIRM_BUTTON: 'Отменить билет',
  CANCEL_BUTTON: 'Отмена',
};

const FormDialog = ({
  provider,
  orderId,
  ticketId,
  onChangeInput,
  onCancelTicket,
  onOpenDialog,
}) => {
  const disabledBottom =
    provider === NONE
    || !orderId.length
    || !ticketId.length;

  return (
    <Dialog width={ MIDDLE } onClick={ onOpenDialog }>
      <div className={ styles.dialog }>
        <h3>
          { LABELS.TITLE }
        </h3>
        <div className={ styles.row }>
          <Select
            items={ TRAIN_ITEMS }
            label={ LABELS.PROVIDER }
            value={ provider }
            theme={ BORDER }
            onChange={ value => onChangeInput('provider', value.value) }
          />
        </div>
        <div className={ styles.row }>
          <Input
            placeholder={ LABELS.ORDER_ID }
            value={ orderId }
            onChange={ value => onChangeInput('orderId', value) }
          />
        </div>
        <div className={ styles.row }>
          <Input
            placeholder={ LABELS.NUMBER_ID }
            value={ ticketId }
            onChange={ value => onChangeInput('ticketId', value) }
          />
        </div>
        <div className={ styles.action }>
          <Button
            theme={ RED }
            disabled={ disabledBottom }
            onClick={ onCancelTicket }
          >
            { LABELS.CONFIRM_BUTTON }
          </Button>
          <div className={ styles.cancel }>
            <FlatButton
              onClick={ onOpenDialog }
            >
              { LABELS.CANCEL_BUTTON }
            </FlatButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

FormDialog.propTypes = {
  provider: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  ticketId: PropTypes.string.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  onCancelTicket: PropTypes.func.isRequired,
  onOpenDialog: PropTypes.func.isRequired,
};

export default FormDialog;
