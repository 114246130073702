import React from 'react';
import PropTypes from 'prop-types';

import Region from './Region';

const AddRegion = ({ regionsService, workplaceService, toRegion }) => (
  <Region isAdd regionsService={ regionsService } workplaceService={ workplaceService } toRegion={ toRegion } />
);

AddRegion.propTypes = {
  regionsService: PropTypes.object.isRequired,
  workplaceService: PropTypes.object.isRequired,
  toRegion: PropTypes.func.isRequired,
};

export default AddRegion;
